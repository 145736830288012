<template>
  <v-form @submit.prevent="submit">
    <v-row class="mb-n6">
      <v-col>
        <v-text-field label="Nombre Completo" dense outlined v-model="nombre"></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="Correo electrónico"
          dense
          outlined
          v-model="correo"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="mb-n6">
      <v-col>
        <v-text-field label="Asunto" dense outlined v-model="asunto"></v-text-field>
      </v-col>
    </v-row>
    <v-row class="mb-n6">
      <v-col>
        <v-textarea
          dense
          label="Tu mensaje"
          auto-grow
          outlined
          rows="8"
          row-height="20"
          v-model="mensaje"
        ></v-textarea>
      </v-col>
    </v-row>
    <div v-if="alerta">
      <v-alert text outlined dense :type="tipoAlerta">{{ mensajeAlerta }}</v-alert>
    </div>

    <v-row>
      <v-col>
        <v-btn class="my-2" color="primary" type="submit" :loading="cargaBoton"
          >ENVIAR</v-btn
        >
        <v-btn class="my-2 ml-3" @click="limpiarDatos">LIMPIAR</v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import utility from "@/js/utility";

export default {
  data: () => ({
    cargaBoton: false,
    nombre: null,
    correo: null,
    asunto: null,
    mensaje: null,
    alerta: false,
    mensajeAlerta: null,
    tipoAlerta: null,
    intervalo: null,
  }),

  methods: {
    limpiarDatos: function () {
      this.nombre = "";
      this.correo = "";
      this.asunto = "";
      this.mensaje = "";
    },

    submit: function () {
      this.cargaBoton = true;
      const datos = {
        nombre: this.nombre,
        correo: this.correo,
        asunto: this.asunto,
        mensaje: this.mensaje,
      };
      utility
        .enviarCorreo(datos)
        .then((response) => {
          console.log(response.data);

          switch (response.data.mensaje) {
            case "enviado":
              this.alerta = true;
              this.mensajeAlerta = "Correo enviado";
              this.tipoAlerta = "success";
              this.limpiarDatos();
              break;

            case "noEnviado":
              this.alerta = true;
              this.mensajeAlerta = "Correo no enviado intentelo más tarde";
              this.tipoAlerta = "error";
              break;

            case "error":
              this.alerta = true;
              this.mensajeAlerta = "Error al tratar de enviar el correo";
              this.tipoAlerta = "error";
              break;

            case "errorDeValidacion":
              this.alerta = true;
              this.mensajeAlerta = "No deje campos vacios";
              this.tipoAlerta = "error";
              break;

            default:
              this.alerta = true;
              this.mensajeAlerta = "Error al tratar de enviar el correo";
              this.tipoAlerta = "error";
              break;
          }
        })
        .finally(() => {
          this.cargaBoton = false;
        });
    },
  },

  watch: {
    alerta(val) {
      if (val) {
        this.intervalo = setInterval(() => {
          this.alerta = false;
          clearInterval(this.intervalo);
        }, 5000);
      }
    },
  },
};
</script>
