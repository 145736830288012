<template>
  <section>
    <v-row no-gutters>
      <v-col cols="12">
        <HeroAlt :hero-alt="heroAlt" />
        <v-container>
          <v-row class="py-16">
            <v-col md="5" cols="12">
              <div class="text-h6 font-weight-bold mt-12 mb-5">Horario de atención</div>
              <v-list two-line class="transparent">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary"> mdi-clock </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>LUNES A VIERNES </v-list-item-title>
                    <v-list-item-subtitle>09:00HRS A 17:00HRS</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary"> mdi-clock </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>SÁBADO</v-list-item-title>
                    <v-list-item-subtitle>09:00HRS A 14:00HRS</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary"> mdi-clock </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>DOMINGO</v-list-item-title>
                    <v-list-item-subtitle>CERRADO</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col md="7" cols="12">
              <div class="text-h4 font-weight-black mb-8">Envíanos un mensaje</div>
              <p class="mb-10">
                Envianos tus datos y nosotros nos pondremos en contacto contigo.
              </p>
              <contactForm />
            </v-col>
          </v-row>
        </v-container>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d486.38210000890336!2d-91.80809759821835!3d18.651158097496428!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85f107a151d03ae1%3A0xb508734d2d149c35!2sQUIOS%20Centro%20M%C3%A9dico%20Est%C3%A9tico!5e0!3m2!1ses-419!2smx!4v1644094318666!5m2!1ses-419!2smx"
          width="100%"
          height="600"
          frameborder="0"
          style="border: 0; display: block"
          allowfullscreen="true"
          aria-hidden="false"
          tabindex="0"
        ></iframe>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import contactForm from "@/components/sections/contactForm.vue";
import HeroAlt from "@/components/sections/HeroAlt";
export default {
  components: {
    HeroAlt,
    contactForm,
  },
  data() {
    return {
      heroAlt: [
        {
          src: "pexels-andrea-piacquadio-3830745.jpg",
          heading: " Contáctanos ",
        },
      ],
    };
  },
  head() {
    return {
      title: "Contacto",
      meta: [
        {
          hid: "description",
          name: "description",
          content:
            "Infographic hypotheses influencer user experience Long madel ture gen-z paradigm shift client partner network product seilans solve management influencer analytics leverage virality. incubator seed round massmarket. buyer agile development growth hacking business-to-consumer ecosystem",
        },
      ],
    };
  },
   mounted() {
    window.scrollTo(0, 0);
  },

};
</script>

<style scoped>
#footer-top {
  display: none !important;
}
</style>
